<template>
    <WitModal
        v-model="showModal"
        title="Advanced Settings"
        variant="warning"
        disableDBlock
        size="lg"
        customClass="workflow-builder-task-settings__modal"
        @input="onModalChange"
    >
        <b-form-group label="Retry options" :label-cols="2" :horizontal="true">
            <b-row>
                <b-col md="10" class="vue-slider-col">
                    <b-form-checkbox-group id="retry-options">
                        <label
                            class="switch switch-label switch-pill switch-primary switch-show-password"
                            data-children-count="1"
                        >
                            <input class="switch-input" type="checkbox" v-model="retryForm.retryOptions" />
                            <span class="switch-slider" data-checked="On" data-unchecked="Off"></span>
                        </label>
                    </b-form-checkbox-group>
                </b-col>
            </b-row>
        </b-form-group>

        <div v-if="retryForm.retryOptions">
            <b-form-group label="Retry attempts" :label-cols="2" :horizontal="true">
                <b-row>
                    <b-col cols="4">
                        <b-form-spinbutton
                            id="step-modal-map-retry-attempts-spin"
                            v-model="$v.retryForm.retryAttempts.$model"
                            :min="1"
                            :max="4"
                            inline
                        ></b-form-spinbutton>
                    </b-col>
                    <b-col md="6" class="vue-slider-col">
                        <vue-slider
                            v-model="$v.retryForm.retryAttempts.$model"
                            :min="1"
                            :max="4"
                            id="step-modal-map-retry-attempts"
                        />
                        <Feedback :state="validateRef('retryForm.retryAttempts')"></Feedback>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-form-group label="Retry multiplier" :label-cols="2" :horizontal="true">
                <b-row>
                    <b-col cols="4">
                        <b-form-spinbutton
                            id="step-modal-map-retry-delay-spin"
                            v-model="$v.retryForm.retryDelay.$model"
                            :min="1"
                            :max="5"
                            inline
                        ></b-form-spinbutton>
                    </b-col>
                    <b-col md="6" class="vue-slider-col">
                        <vue-slider
                            v-model="$v.retryForm.retryDelay.$model"
                            :min="1"
                            :max="5"
                            id="step-modal-map-retry-delay"
                        />
                        <Feedback :state="validateRef('retryForm.retryDelay')"></Feedback>
                    </b-col>
                </b-row>
            </b-form-group>
        </div>

        <b-row>
            <b-col>
                <b-button @click="finish" :disabled="isPageChecked && $v.$invalid" variant="primary">
                    <i class="icon-check"></i>
                    <span> Save</span>
                </b-button>
            </b-col>
        </b-row>
    </WitModal>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {requiredIf} from 'vuelidate/lib/validators'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

import WitModal from '@/components/Modals/WitModal.vue'
import Feedback from '@/components/Feedback.vue'

export default {
    components: {
        WitModal,
        Feedback,
        VueSlider,
    },
    mixins: [validationMixin],
    props: {
        value: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            retryForm: {
                retryAttempts: 4,
                retryDelay: 5,
                retryOptions: false,
            },
            showModal: false,
            isPageChecked: false,
        }
    },
    validations: {
        retryForm: {
            retryOptions: {},
            retryAttempts: {
                required: requiredIf(function () {
                    return this.retryForm.retryOptions
                }),
            },
            retryDelay: {
                required: requiredIf(function () {
                    return this.retryForm.retryOptions
                }),
            },
        },
    },
    computed: {
        retryValue() {
            if (this.retryForm.retryOptions) {
                const {retryAttempts, retryDelay} = this.retryForm
                return {retryAttempts, retryDelay}
            }

            return {}
        },
    },
    created() {
        this.init()
    },
    watch: {
        show() {
            this.init()
            this.isPageChecked = false
            this.$v.$reset()
        },
    },
    methods: {
        init() {
            if (this.value) {
                const {retryAttempts, retryDelay} = this.value
                this.retryForm.retryOptions = retryAttempts || retryDelay
                this.retryForm.retryAttempts = retryAttempts ? retryAttempts : this.retryForm.retryAttempts
                this.retryForm.retryDelay = retryDelay ? retryDelay : this.retryForm.retryDelay
            }

            this.showModal = this.show
        },

        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty || this.isPageChecked ? !validateField.$invalid : null
        },

        emitInput() {
            this.$emit('input', this.retryValue)
        },

        onModalChange() {
            this.$emit('update:show', this.showModal)
        },

        async finish() {
            this.isPageChecked = true
            await this.$nextTick()

            if (!this.$v.$invalid) {
                this.emitInput()

                this.showModal = false
                this.onModalChange()
            }
        },
    },
}
</script>

<style></style>
